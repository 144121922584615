<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="col-12 text-center"
              >Всего:
              {{ totalBalance | number("0,0", { thousandsSeparator: " " }) }}
              ₽</v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(balance, idx) of balances" :key="idx">
              <div class="font-weight-bold">{{ balance.name }}</div>
              <div class="font-weight-bold" v-if="balance.amount">
                Баланс: {{ balance.amount }} ₽
              </div>
              <div>
                <div v-if="balance.frozen">
                  Заморожено: {{ balance.frozen }} ₽
                </div>
                <div v-if="balance.hold">Hold: {{ balance.hold }} ₽</div>
                <div v-if="balance.limits">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      оборот в месяц
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-cash
                      </v-icon>
                    </template>
                    <span>{{ balance.limits[0] }}</span>
                  </v-tooltip>
                </div>
                <div v-if="balance.limits">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      максимальный допустимый остаток на счёте
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-account-cash
                      </v-icon>
                    </template>
                    <span>{{ balance.limits[1] }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PaymentsService from "@/services/payments.service";

export default {
  name: "PageBalances",
  data: () => ({
    balances: [],
    totalBalance: 0
  }),
  methods: {
    loadPaymentsGatesBalances() {
      PaymentsService.paymentGatesBalances().then(data => {
        this.balances = data;
        console.log(this.balances);
        data.forEach(item => {
          this.totalBalance += parseInt(item.amount);
        });
      });
    }
  },
  created() {
    this.loadPaymentsGatesBalances();
  }
};
</script>

<style scoped></style>
