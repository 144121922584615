<template>
  <v-row>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-col v-if="!loading">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="col-12 text-center"
              >Всего:
              {{
                (totalOut / 100) | number("0,0", { thousandsSeparator: " " })
              }}
              ₽</v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="10"
            loading-text="Загрузка... Пожалуйста подождите"
          >
            <template v-slot:top>
              <v-dialog
                v-model="confirmDialogShow"
                max-width="400"
                v-if="transactions.length"
              >
                <v-card>
                  <v-card-title class="headline">Вы уверенны?</v-card-title>
                  <v-card-text>
                    <v-overlay :value="transactionApplyLoader" :absolute="true">
                      <v-progress-circular
                        indeterminate
                        size="64"
                      ></v-progress-circular>
                    </v-overlay>
                    <div v-if="transactions[editedIndex]">
                      ID Транзакции: {{ transactions[editedIndex].id }}
                    </div>
                    <div v-if="transactions[editedIndex]">
                      Сумма:
                      {{ (transactions[editedIndex].amount / 100).toFixed(2) }}
                      Руб.
                    </div>
                    <div v-if="paymentsSystems.length">
                      Платежный шлюз:
                      {{
                        paymentsSystems.find(item => item.id === systemId).title
                      }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="closeTransactionConfirm">
                      Нет
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="withdrawTransactionConfirm"
                    >
                      Да
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="changeDialogShow"
                max-width="400"
                v-if="transactions.length"
              >
                <v-card>
                  <v-card-title class="headline"
                    >Изменить статус вручную</v-card-title
                  >
                  <v-card-text>
                    <v-overlay :value="transactionApplyLoader" :absolute="true">
                      <v-progress-circular
                        indeterminate
                        size="64"
                      ></v-progress-circular>
                    </v-overlay>
                    <div v-if="transactions[editedIndex]">
                      ID Транзакции: {{ transactions[editedIndex].id }}
                    </div>
                    <div v-if="transactions[editedIndex]">
                      Сумма:
                      {{ (transactions[editedIndex].amount / 100).toFixed(2) }}
                      Руб.
                    </div>
                    <div>
                      <v-select
                        v-model="changeStatus"
                        label="Выберите статус"
                        :items="[
                          { value: 1, name: 'Создана' },
                          { value: 2, name: 'Выполнена' },
                          { value: 3, name: 'В процессе' },
                          { value: 10, name: 'Отменена' }
                        ]"
                        item-text="name"
                        item-value="value"
                      >
                      </v-select>
                    </div>
                    <div>
                      Комментарий:
                      <v-text-field
                        v-model="newCommentFromAdmin"
                        label="коментарий к транзакции"
                        single-line
                      />
                    </div>
                    <div v-if="changeStatus === 10">
                      <v-select
                        v-model="reasonId"
                        label="Выберите причину"
                        :items="[
                          { value: 0, name: 'Неверные реквизиты' },
                          { value: 1, name: 'Проблемный банк' },
                          { value: 2, name: 'Отменен платежной системой' },
                          { value: 3, name: 'Не отыгран депозит' },
                          { value: 4, name: 'Свяжитесь с тех поддержкой' },
                          { value: 5, name: 'Своя причина' }
                        ]"
                        item-text="name"
                        item-value="value"
                      >
                      </v-select>
                      <div v-if="reasonId === 5">
                        Своя причина:
                        <v-text-field
                          v-model="reason"
                          label="Своя причина"
                          single-line
                        />
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="changeDialogShow = false">
                      Нет
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="manualyEdit(transactions[editedIndex])"
                    >
                      Да
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="DeclineModalShow" max-width="400">
                <v-card>
                  <v-card-title class="headline"
                    >Отклонить транзакцию</v-card-title
                  >
                  <v-card-text>
                    <div v-if="targetDeclineTransaction">
                      ID Транзакции: {{ targetDeclineTransaction.id }}
                    </div>
                    <div v-if="targetDeclineTransaction">
                      Сумма:
                      {{ (targetDeclineTransaction.amount / 100).toFixed(2) }}
                      Руб.
                    </div>
                    <div v-if="targetDeclineTransaction">
                      Платежный шлюз:
                      {{ targetDeclineTransaction.paymentsSystems.title }}
                    </div>
                    <div>
                      <v-select
                        v-model="reasonId"
                        label="Выберите причину"
                        :items="[
                          { value: 0, name: 'Неверные реквизиты' },
                          { value: 1, name: 'Проблемный банк' },
                          { value: 2, name: 'Отменен платежной системой' },
                          { value: 3, name: 'Не отыгран депозит' },
                          { value: 4, name: 'Свяжитесь с тех поддержкой' },
                          { value: 5, name: 'Своя причина' }
                        ]"
                        item-text="name"
                        item-value="value"
                      >
                      </v-select>
                      <div v-if="reasonId === 5">
                        Своя причина:
                        <v-text-field
                          v-model="reason"
                          label="Своя причина"
                          single-line
                        />
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="DeclineModalShow = false">
                      Нет
                    </v-btn>
                    <v-btn color="primary" text @click="cancelWithdraw">
                      Да
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.user="{ item }">
              <router-link tag="div" :to="`/users/${item.cards.id}`">
                <v-btn color="primary">
                  {{ item.cards.nick }}
                  <v-icon v-if="item.cards.is_fake" dark right color="red">
                    mdi-star
                  </v-icon>
                </v-btn>
              </router-link>
            </template>
            <template v-slot:item.course="{ item }">
              <span class="font-weight-bold">
                {{ item.course.toFixed(2) }}
              </span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span class="font-weight-bold">
                {{ (item.amount / item.course / 100).toFixed(2) }}
              </span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.purse="{ item }">
              {{ item.paymentsWays.name }}
            </template>
            <template v-slot:item.actions="{ item }">
              <span v-if="item.payerCurrency === 'RUB'">
                <v-chip
                  v-for="method in uniqueMethods(item.methods)"
                  :key="method.id"
                  v-show="methodTitle(method)"
                  color="green"
                  @click="withdrawTransaction(item, method.psId, method)"
                >
                  {{ methodTitle(method) }}
                </v-chip>
              </span>
              <v-chip color="red" @click="declineTransaction(item)">
                Отклонить
              </v-chip>
              <v-chip color="orange" @click="changeStatusTransaction(item)">
                Вручную
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PaymentsService from "@/services/payments.service";
import { mapGetters } from "vuex";

export default {
  name: "WithdrawList",
  components: {},
  data: () => ({
    method: null,
    targetDeclineTransaction: null,
    newCommentFromAdmin: "",
    reason: "",
    reasonId: 0,
    transactions: [],
    headers: [
      { text: "ID", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Комментарий", value: "comment" },
      { text: "Валюта", value: "payerCurrency" },
      { text: "Курс", value: "course" },
      { text: "Сумма", value: "amount" },
      { text: "Платежный метод", value: "purse" },
      { text: "Реквизиты", value: "purseNumber" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Действия", value: "actions" }
    ],
    loading: false,
    confirmDialogShow: false,
    changeDialogShow: false,
    DeclineModalShow: false,
    changeStatus: 2,
    editedIndex: 0,
    systemId: 1,
    paymentsSystems: [],
    totalOut: 0,
    transactionApplyLoader: false
  }),
  computed: {
    ...mapGetters("user", ["getProfileAdmin"])
  },
  methods: {
    methodTitle(method) {
      const system = this.paymentsSystems.find(ps => ps.id === method.psId);
      return system ? system.title : null;
    },
    uniqueMethods(methods) {
      const seen = new Set();
      return methods.filter(method => {
        const duplicate = seen.has(method.psId);
        seen.add(method.psId);
        return !duplicate;
      });
    },
    loadWithdrawalsTransactions() {
      // this.loading = true;
      PaymentsService.findMany({
        where: {
          state: 1,
          code: 2,
          isFake: 0
        },
        include: {
          cards: true,
          paymentsWays: true,
          paymentsCodes: true,
          paymentsStates: true,
          paymentsSystems: true
        }
      }).then(async data => {
        // this.transactions = data;
        this.transactions = await Promise.all(
          data.map(async transaction => {
            this.totalOut += transaction.amount;
            const methods = await PaymentsService.getMethodWithdraw({
              id: transaction.id
            });
            return {
              ...transaction,
              methods
            };
          })
        );
        for (let i in this.transactions) {
          let bankName = JSON.parse(this.transactions[i].comment);
          if (bankName) {
            this.transactions[i].comment = bankName.bank_name;
          }
        }
        this.loading = false;
      });
    },
    declineTransaction(item) {
      console.log(item);
      this.targetDeclineTransaction = item;
      this.DeclineModalShow = true;
    },
    cancelWithdraw() {
      switch (this.reasonId) {
        case 0:
          this.reason = "Неверные реквизиты";
          break;
        case 1:
          this.reason = "Проблемный банк";
          break;
        case 2:
          this.reason = "Отменен платежной системой";
          break;
        case 3:
          this.reason = "Не отыгран депозит";
          break;
        case 4:
          this.reason = "Свяжитесь с тех поддержкой";
          break;
      }
      PaymentsService.cancelWithdraw({
        id: this.targetDeclineTransaction.id,
        reason: this.reason,
        admin_id: this.getProfileAdmin
      }).then(
        () => {
          this.loadWithdrawalsTransactions();
          this.DeclineModalShow = false;
          this.reason = "";
        },
        e => {
          console.log(e);
          this.$noty.error(e.response.data.message);
        }
      );
    },
    withdrawTransaction(item, systemId, method) {
      this.systemId = systemId;
      this.editedIndex = this.transactions.indexOf(item);
      this.confirmDialogShow = true;
      this.method = method;
    },
    withdrawTransactionConfirm() {
      this.transactionApplyLoader = true;
      PaymentsService.withdraw({
        id: this.transactions[this.editedIndex].id,
        systemId: this.systemId,
        method: this.method
      }).then(
        () => {
          this.closeTransactionConfirm();
          this.loadWithdrawalsTransactions();
          this.transactionApplyLoader = false;
        },
        e => {
          this.$noty.error(e.response.data.message);
          this.transactionApplyLoader = false;
          this.closeTransactionConfirm();
          this.loadWithdrawalsTransactions();
        }
      );
    },
    closeTransactionConfirm() {
      this.confirmDialogShow = false;
    },
    getPaymentsSystems() {
      PaymentsService.getPaymentsSystems().then(
        data => (this.paymentsSystems = data)
      );
    },
    changeStatusTransaction(item) {
      console.log(item);
      this.editedIndex = this.transactions.indexOf(item);
      this.changeDialogShow = true;
      this.newCommentFromAdmin = "";
      this.reasonId = 0;
      this.reason = "";
    },
    manualyEdit(item) {
      PaymentsService.findUnique({
        where: {
          id: item.id
        }
      }).then(async data => {
        if (data.state !== item.state) {
          this.$noty.error("У вас неоктуальная информация о платеже");
          this.transactionApplyLoader = true;
          this.loadWithdrawalsTransactions();
          this.changeDialogShow = false;
          this.transactionApplyLoader = false;
        } else {
          this.transactionApplyLoader = true;
          switch (this.reasonId) {
            case 0:
              this.reason = "Неверные реквизиты";
              break;
            case 1:
              this.reason = "Проблемный банк";
              break;
            case 2:
              this.reason = "Отменен платежной системой";
              break;
            case 3:
              this.reason = "Не отыгран депозит";
              break;
            case 4:
              this.reason = "Свяжитесь с тех поддержкой";
              break;
          }
          PaymentsService.update({
            data: {
              state: this.changeStatus,
              admin_id: this.getProfileAdmin || 2,
              commentFromAdmin: this.newCommentFromAdmin,
              reason: this.changeStatus === 10 ? this.reason : "",
              system: 100
            },
            where: {
              id: item.id
            }
          })
            .then(() => {
              this.loadWithdrawalsTransactions();
              this.$noty.success("Успешно");
              this.changeDialogShow = false;
              this.transactionApplyLoader = false;
              this.editedIndex = 0;
              this.reason = "";
              this.newCommentFromAdmin = "";
            })
            .catch(e => {
              this.$noty.error(e.message);
              this.transactionApplyLoader = false;
            });
        }
      });
    }
  },
  created() {
    this.getPaymentsSystems();
    this.loadWithdrawalsTransactions();
  }
};
</script>

<style scoped></style>
