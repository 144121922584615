<template>
  <v-container fluid>
    <!--    <Balances v-show="checkRoles(['general', 'majorSupport', 'analitic'])" />-->
    <Balances />
    <WithdrawList />
    <!--    <WithdrawHistory-->
    <!--      v-show="checkRoles(['general', 'majorSupport', 'analitic', 'support'])"-->
    <!--    />-->
    <WithdrawHistory />
  </v-container>
</template>

<script>
import Balances from "@/views/pages/Withdrawals/Balances";
import WithdrawList from "@/views/pages/Withdrawals/WithdrawList";
import WithdrawHistory from "@/views/pages/Withdrawals/WithdrawHistory";
export default {
  name: "Index",
  components: {
    Balances,
    WithdrawList,
    WithdrawHistory
  },
  data: () => ({}),
  methods: {}
};
</script>

<style scoped></style>
